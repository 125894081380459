import React, { useRef } from "react";
import { IoIosSearch } from "react-icons/io";
import { useState } from "react";
import { FiMenu } from "react-icons/fi";

const Header = () => {
  const aboutUsRef = useRef(null);
  const ourServicesRef = useRef(null);

  const NavElement = [
    { name: "Home", link: "/" },
    { name: "About us", link: "#about-us", ref: aboutUsRef },
    { name: "Our Services", link: "#our-services", ref: ourServicesRef },
    { name: "Contact Us", link: "/contact" },
  ];

  let [openMenu, setopenMenu] = useState(false);
  const handleMenuClick = (link) => {
    if (link.ref && link.ref.current) {
      link.ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      window.location.href = link.link;
    }
    setopenMenu(false);
  };

  return (
    <div className="w-full bg-white  md:h-20 sm:h-fit flex flex-row justify-around items-center sm:flex-col md:flex-row fixed z-10">
      <div className="w-[40%] flex justify-center sm:justify-between sm:p-[4%] sm:items-center sm:w-full sm:h-16 md:justify-center md:w-[40%]">
        <img
          src="/images/mindstarlogo1.png"
          alt="logo"
          className="sm:w-[13rem] lg:w-[18rem] "
        />
        <button className="md:hidden sm:block">
          <IoIosSearch className="h-6 w-6" />
        </button>
        <button
          onClick={() => setopenMenu(!openMenu)}
          className="md:hidden sm:block"
        >
          <FiMenu />
        </button>
      </div>
      <div
        className={`flex w-[40%] md:w-full md:flex-row md:justify-around md:static sm:top-[100%] sm:z-[99] sm:absolute  sm:flex-col sm:w-full ${
          openMenu ? "block" : "hidden md:flex"
        }`}
      >
        {NavElement.map((link) => (
          <div
            key={link.name}
            className="sm:text-xl md:text-sm lg:text-xl sm:w-[50%] hover:underline hover:underline-offset-8 sm:ml-[50%] md:ml-0 sm:p-[2%] hover:decoration-[#CC7722] sm:flex sm:justify-end bg-white font-medium"
          >
            <button onClick={() => handleMenuClick(link)}>{link.name}</button>
          </div>
        ))}
      </div>
      <div className="w-[20%] flex justify-center sm:hidden md:flex md:w-[20%] lg:flex">
        <IoIosSearch className="h-6 w-6" />
      </div>
    </div>
  );
};

export default Header;
