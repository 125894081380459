import React from "react";
import Slider from "react-slick";

const Testimonial = () => {
  const testimonials = [
    {
      quote:
        "I always get what I expect and in a timely manner. Customer service is friendly as well as professional",
      author: "Senjuti Chakraborty",
      image: "images/Flat_bubble_for_quote_with_yellow_banner.png",
    },
    {
      quote:
        "In my life long experiences as a buyer I have never been so amazed as I have been with Mindstar. I just want to thank the team for all the caring efforts that have been expressed from their end.",
      author: "Sougata Mukherjee",
      image: "images/Flat_bubble_for_quote_with_yellow_banner_2.png",
    },
    {
      quote:
        "I always get what I expect and in a timely manner. Customer service is friendly as well as professional",
      author: "Senjuti Chakraborty",
      image: "images/Flat_bubble_for_quote_with_yellow_banner.png",
    },
    {
      quote:
        "In my life long experiences as a buyer I have never been so amazed as I have been with Mindstar. I just want to thank the team for all the caring efforts that have been expressed from their end.",
      author: "Sougata Mukherjee",
      image: "images/Flat_bubble_for_quote_with_yellow_banner_2.png",
    },
  ];
  const CustomDots = ({ active, onClick }) => (
    <span
      className={`w-3 h-3 flex gap-y-5 flex-row space-x-3 rounded-full cursor-pointer  ${
        active ? "bg-yellow-500" : "bg-purple-900"
      }`}
      onClick={onClick}
    ></span>
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    centerMode: true,
    centerPadding: "9rem",
    appendDots: (dots) => (
      <div className="flex items-center justify-center mx-5 w-full">{dots}</div>
    ),
    customPaging: (i, active) => <CustomDots key={i} active={active} />,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          centerPadding: "20rem",
        },
      },
      {
        breakpoint: 1020,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "10rem",
        },
      },
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "5rem",
        },
      },
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "5rem",
        },
      },
    ],
  };

  return (
    <div
      className="w-screen h-fit sm:p-8 lg:p-16 bg-no-repeat"
      style={{ backgroundImage: "url(/images/slider_bg_1.png)" }}
    >
      <div className="flex justify-center flex-col items-center font-bold text-2xl pb-10">
        <h1 className="text-2xl font-bold text-black">Testimonials</h1>
        <div className="w-32 rounded-md h-[1.5px] bg-red-600"></div>
      </div>

      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="carousel-slide relative">
            <img
              src={testimonial.image}
              className="md:h-[25rem] sm:h-[18rem] md:w-[30rem]"
              alt={`Testimonial ${index + 1}`}
              useMap={`#imagemap${index}`}
            />
            <div className="absolute sm:w-[12rem] md:w-[17.5rem] top-[14%] left-[5%] text-white transform-translate-[-50%, -50%] sm:text-sm md:text-xl font-bold">
              "{testimonial.quote}"
            </div>
            <div className="absolute top-[64%] left-[38%] text-xl font-bold text-white transform-translate-[-50%, -50%]">
              {testimonial.author}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Testimonial;
