import React, { useState } from "react";
import { FaCircle } from "react-icons/fa6";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const AboutSection = () => {
  let [show, setShow] = useState(false);
  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    fade: true,

    vertical: true,
    verticalSwiping: true,
  };
  return (
    <div
      id="about-us"
      className="flex lg:flex-row sm:flex-col p-[5%] scroll-animation"
    >
      <div className="lg:w-[50%] sm:w-full flex justify-center items-center">
        <Slider {...sliderSettings}>
          <div className=" ">
            <img
              src="/images/about.png"
              alt="about"
              className="sm:w-[25rem] sm:h-[25rem] lg:w-[32rem] lg:h-[30rem] sm:mx-auto"
            />
          </div>
          <div>
            <img
              src="/images/about2.png"
              alt="about"
              className="sm:w-[25rem] sm:h-[25rem] lg:w-[32rem] lg:h-[30rem] sm:mx-auto"
            />
          </div>
          <div>
            <img
              src="/images/about3.png"
              alt="about"
              className="sm:w-[25rem] sm:h-[25rem] lg:w-[32rem] lg:h-[30rem] sm:mx-auto"
            />
          </div>
        </Slider>
      </div>

      <div className="lg:w-[50%] md:pl-10 sm:w-full">
        <div class="flex flex-row space-x-0.5 md:justify-start sm:justify-center">
          <div class="h-1 bg-[#df4b23] mt-4 w-24"></div>
          <div class="text-[#532470] font-semibold text-xl">About Us</div>
        </div>
        <div className="text-3xl font-bold sm:flex sm:justify-center md:block">
          What we are:
        </div>
        <div>
          As a software development company, we are providing solutions that
          empower your business globally and help to reach your business goals.
          Our team of dedicated software developers has experience working on
          various projects whether it is e-commerce software development or
          custom software development for any other industry with professional
          design solutions.
          {show && (
            <div>
              <span>
                Our team efficiently works with all clients. We also have
                experience as an app development agency that builds mobile fully
                responsive and functional applications. We have skilled mobile
                app developers for both Android app development & iPhone app
                development. Our team is also experienced in different aspects
                of Graphics Design like Logo design, UX-UI design and many more.
                We are working on the following objectives - Creating new
                business models to deliver products and services to the clients
                Improved decision making Operational Excellence Survival
                Employee, vendor, customer and community engagement and
                relations. We are working on the following objectives - Creating
                new business models to deliver products and services to the
                clients Improved decision making Operational Excellence Survival
                Employee, vendor, customer and community engagement and
                relations
              </span>
              <div className="flex flex-col p-8">
                <span>
                  The following are some of the Unique Selling Propositions of
                  Mindstar –
                </span>
                <div className="flex flex-col p-4">
                  <span className="flex items-center gap-4">
                    <FaCircle className="w-3 h-3 text-[#582476]" /> Web and
                    Mobile App with low response time
                  </span>
                  <span className="flex items-center gap-4">
                    <FaCircle className="w-3 h-3 text-[#582476]" /> User
                    friendly Menu Driven UI and smooth flow between Pages
                  </span>
                  <span className="flex items-center gap-4">
                    <FaCircle className="w-3 h-3 text-[#582476]" /> Sticky User
                    Interface Design for more User Time
                  </span>
                  <span className="flex items-center gap-4">
                    <FaCircle className="w-3 h-3 text-[#582476]" /> Big Data
                    handling with efficient Database design
                  </span>
                  <span className="flex items-center gap-4">
                    <FaCircle className="w-3 h-3 text-[#582476]" /> Application
                    of Machine Learning on Big & Live Business Data
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="sm:flex sm:justify-center md:justify-start  p-4">
          <button
            onClick={() => setShow(!show)}
            className="w-32 rounded-md text-lg font-medium bg-[#582476] text-white h-10"
          >
            {show ? "View less" : "View more"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
