import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Banner() {
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
  };
  const slides = [
    {
      imageSrc: "/images/banner1.jpg",
      title: "Flawless Software Solutions",
    },
    {
      imageSrc: "/images/mindstar-banner2.jpg",
      title: "Flawless Software Solutions",
    },
    {
      imageSrc: "/images/mindstar-banner3.jpg",
      title: "Flawless Software Solutions",
    },
  ];
  return (
    <div className="  relative sm:mt-[70px] md:mt-[100px]">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div className="carousel-item relative" key={index}>
            <img
              src={slide.imageSrc}
              alt=""
              className="w-full resize sm:h-[200px] md:h-[350px] lg:h-[600px]"
            />
            <div className="absolute inset-0 flex flex-col items-center justify-center bg-opacity-75  p-16 font-[Montserrat] font-bold text-white  sm:text-xl md:w-full md:text-3xl lg:w-[60%]">
              <p className="custom-text-stroke">{slide.title}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Banner;
