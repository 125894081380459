import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaThreads,
  FaWhatsapp,
} from "react-icons/fa6";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
const Footer = () => {
  return (
    <div>
      <div className="lg:flex md:grid md:grid-cols-2 sm:flex-col sm:p-8 lg:p-0 w-screen lg:flex-row md:h-fit lg:h-96 sm:h-fit items-center justify-evenly bg-[#592476] text-white">
        <div className=" md:w-80 sm:w-full sm:h-fit sm:p-[3%] md:p-[3%] lg:p-0">
          <img src="images/mindstarlogo1.png" alt="" className="mb-4" />
          <p>
            Our customers' needs are of the utmost importance. We are committed
            to meeting those needs. As a result, a high percentage of our
            business is from repeat customers and referrals. We welcome the
            opportunity to earn your trust and deliver you the best service in
            the industry.
          </p>
        </div>

        <div className=" md:w-64 sm:w-full sm:h-fit sm:p-[3%] md:p-[3%] lg:p-0">
          <p className="font-xl font-bold mt-8 mb-2">ABOUT</p>
          <ul>
            <a href="/">
              <li className="flex items-center text-lg ">
                <MdKeyboardDoubleArrowRight className="font-bold mr-2 mt-1" />
                History
              </li>
            </a>
            <a href="/">
              <li className="flex items-center text-lg ">
                <MdKeyboardDoubleArrowRight className="font-bold mr-2 mt-1" />
                Our Team
              </li>
            </a>
            <a href="/">
              <li className="flex items-center text-lg ">
                <MdKeyboardDoubleArrowRight className="font-bold mr-2 mt-1" />
                Brand Guidelines
              </li>
            </a>
            <a href="/">
              <li className="flex items-center text-lg ">
                <MdKeyboardDoubleArrowRight className="font-bold mr-2 mt-1" />
                Terms & Condition
              </li>
            </a>
            <a href="/">
              <li className="flex items-center text-lg ">
                <MdKeyboardDoubleArrowRight className="font-bold mr-2 mt-1" />
                Privacy Policy
              </li>
            </a>
          </ul>
        </div>

        <div className=" md:w-64 sm:w-full sm:h-fit sm:p-[3%] md:p-[3%] lg:p-0">
          <p className="font-xl font-bold mt-8 mb-2">SERVICES</p>
          <ul>
            <a href="/">
              <li className="flex items-center text-lg ">
                <MdKeyboardDoubleArrowRight className="font-bold mr-2 mt-1" />
                How to Order
              </li>
            </a>
            <a href="/">
              <li className="flex items-center text-lg ">
                <MdKeyboardDoubleArrowRight className="font-bold mr-2 mt-1" />
                Our Product
              </li>
            </a>
            <a href="/">
              <li className="flex items-center text-lg ">
                <MdKeyboardDoubleArrowRight className="font-bold mr-2 mt-1" />
                Order Status
              </li>
            </a>
            <a href="/">
              <li className="flex items-center text-lg ">
                <MdKeyboardDoubleArrowRight className="font-bold mr-2 mt-1" />
                Promo
              </li>
            </a>
            <a href="/">
              <li className="flex items-center text-lg ">
                <MdKeyboardDoubleArrowRight className="font-bold mr-2 mt-1" />
                Payment Method
              </li>
            </a>
          </ul>
        </div>

        <div className=" md:w-80 sm:w-full sm:h-fit sm:p-[3%] md:p-[3%] lg:p-0">
          <p className="font-medium mt-8 mb-2">CONTACT US</p>
          <ul className="flex flex-col space-y-2">
            <p className="flex flex-row space-x-2">
              <img
                src="images/google-location-icon-vector-21.png"
                className="h-[25px] w-[16px]"
                alt=""
              />
              <span>
                : 18C/2, 2no Poddar Nagar,
                <br />
                Jadavpur, Kolkata: 700032
              </span>
            </p>
            <p className="flex flex-row space-x-2">
              <img
                src="images/free-phone-icon-504-thumb.png"
                alt=""
                className="h-[19px] w-[19px]"
              />
              <span>:9674899505</span>
            </p>
            <p className="flex flex-row space-x-2">
              <img
                src="images/logo-website-world-wide-web-the-internet-know-your-meme-7.png"
                alt=""
                className="h-[23px] w-[23px]"
              />
              <span> : techmindstar@gmail.com</span>
            </p>
            <p className="flex flex-row space-x-3">
              <div className="w-10 h-10 flex justify-center items-center rounded-full bg-white">
                <a
                  href="https://instagram.com/techmindstar?igshid=OGQ5ZDc2ODk2ZA=="
                  target="_blank"
                >
                  <FaInstagram className="text-[#592476] w-6 h-6" />
                </a>
              </div>
              <div className="w-10 h-10 flex justify-center items-center rounded-full bg-white">
                <a
                  href="https://www.facebook.com/profile.php?id=61551712687037"
                  target="_blank"
                >
                  <FaFacebook className="text-[#592476] w-6 h-6" />
                </a>
              </div>
              <div className="w-10 h-10 flex justify-center items-center rounded-full bg-white">
                <a
                  href="https://l.threads.net/?u=https%3A%2F%2Fwww.instagram.com%2Ftechmindstar%2F&e=AT3U2fZ2T-8KP6GyzaVX8TmI9HZRo6lA3HGEvLxvibj5tatYz5hnP6pHDzQRx6cLcNBi5GMmEvePUpMVd_HH6bNky1FzoFVMcgVylsgLvx-2hiTewrlaSN-MLCC13edUvxqyx_s"
                  target="_blank"
                >
                  <FaThreads className="text-[#592476] w-6 h-6" />
                </a>
              </div>
              <div className="w-10 h-10 flex justify-center items-center rounded-full bg-white">
                <a href="https://api.whatsapp.com/send?phone=" target="_blank">
                  <FaWhatsapp className="text-[#592476] w-6 h-6" />
                </a>
              </div>
            </p>
          </ul>
        </div>
      </div>
      <div className="lg:h-10 flex sm:justify-center md:p-[4%] sm:flex-col lg:flex-row md:flex-col sm:p-[4%] sm:h-fit items-center text-[#e2dbe6] bg-[#4b1f6e] md:justify-evenly">
        <div>
          Copyright Mindstar Technology Solutions Pvt.Ltd. | All Right Reserved
        </div>
        <div>
          Designed and Developed by Mindstar Technology Solutions Pvt. Ltd.
        </div>
      </div>
    </div>
  );
};

export default Footer;
