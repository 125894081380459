import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const MissionVision = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    centerMode: true,
    centerPadding: "9rem",
    responsive: [
      {
        breakpoint: 768, // Adjust the breakpoint as needed
        settings: {
          slidesToShow: 1,
          centerMode: false,
          centerPadding: "0rem",
        },
      },
    ],
  };

  return (
    <div
      className="bg-center bg-cover bg-no-repeat"
      style={{ backgroundImage: "url(/images/ban2.png)" }}
    >
      <Slider {...settings}>
        <div className=" flex flex-row justify-evenly items-center  lg:p-16 h-fit w-screen">
          <div className="flex flex-col mr-8 sm:p-4 lg:p-8 relative">
            <div>
              <img
                src="/images/oneP.png"
                className="lg:w-[35rem] sm:h-[24rem] md:w-[30rem] lg:h-[28rem]"
                alt=""
              />
              <p className="text-center absolute sm:w-[10rem] lg:w-[15rem] sm:text-sm lg:text-xl sm:top-[54%] lg:top-1/2 sm:left-[16rem] lg:left-[27rem] transform -translate-x-1/2 -translate-y-1/2 text-gray-600">
                Our mission is: to deliver the best possible reliable software
                Solutions to help our clients improve IT efficiency and business
                profitability.
              </p>
            </div>
            <div className="relative top-[-5rem] left-[-10px]">
              <img
                src="/images/01.png"
                className="sm:w-[5rem] sm:h-[5rem] lg:w-[10rem] lg:h-[8rem]"
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="bg-center bg-no-repeat flex flex-row justify-evenly items-center  lg:p-16 h-fit w-screen">
          <div className="flex flex-col mr-8 relative sm:p-4 lg:p-8 ">
            <img
              src="/images/oneP2.png"
              className="lg:w-[35rem] sm:h-[24rem] lg:h-[28rem]"
              alt=""
            />
            <p className="text-center absolute text-xl sm:top-[54%] lg:top-1/2 sm:w-[10rem] lg:w-[15rem] sm:text-sm lg:text-xl sm:left-[16rem] lg:left-[27rem] transform -translate-x-1/2 -translate-y-1/2 text-gray-600">
              To be the benchmark for Software Services in the IT industry
              recognized for the integrity of our people, the ethics of our
              business practice and the quality of our service
            </p>
            <div className="relative top-[-5rem]">
              <img
                src="/images/02.png"
                className="sm:w-[5rem] sm:h-[5rem] lg:w-[10rem] lg:h-[8rem]"
                alt=""
              />
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default MissionVision;
