import "./App.css";
import Banner from "./component/Banner";
import MissonVisson from "./component/MissonVisson";
import OurService from "./component/OurService";
import Testimonial from "./component/Testimonial";
import AboutSection from "./component/AboutSection";
import Footer from "./component/Footer";
import MainPageTemplate from "./template/MainPageTemplate";

function App() {
  return (
    <MainPageTemplate className=" ">
      <Banner />
      <AboutSection />
      <MissonVisson />
      <OurService />
      <Testimonial />
      <Footer />
    </MainPageTemplate>
  );
}

export default App;
